import {
  type CSSObjectWithLabel,
  type GroupBase,
  type StylesConfig,
} from 'react-select';

const theme = {
  primary: '#01ACC4',
  secondary: '#303436',
  danger: 'rgba(239, 68, 68, 1)',
  gray: 'rgba(255, 255, 255, 0.15)',
  layer1: '#101012',
};

export function buildReactSelectStyles<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Option = any,
  Multi extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props?: {
  override?: {
    control?: CSSObjectWithLabel;
    singleValue?: CSSObjectWithLabel;
    valueContainer?: CSSObjectWithLabel;
    dropdownIndicator?: CSSObjectWithLabel;
    placeholder?: CSSObjectWithLabel;
    indicatorsContainer?: CSSObjectWithLabel;
    multiValue?:
      | CSSObjectWithLabel
      | ((data: Option, index: number) => CSSObjectWithLabel);
    clearIndicator?: CSSObjectWithLabel;
    option?: CSSObjectWithLabel;
    groupHeading?: CSSObjectWithLabel;
    menu?: CSSObjectWithLabel;
  };
  isError?: boolean;
}): StylesConfig<Option, Multi, Group> {
  const { override, isError } = props || {};

  return {
    placeholder: (provided) => ({
      ...provided,
      ...override?.placeholder,
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '13px',
      fontWeight: 'normal',
    }),
    control: (provided) => ({
      ...provided,
      background: 'black',
      borderRadius: '12px',
      border: `1px solid ${isError ? theme.danger : theme.secondary}`,
      boxShadow: 'none',
      ':hover': {
        border: `1px solid ${theme.secondary}`,
      },
      ...override?.control,
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
    menu: (provided) => ({
      ...provided,
      background: theme.layer1,
      border: `1px solid ${theme.secondary}`,
      borderRadius: '12px',
      marginTop: '2px',
      ...override?.menu,
    }),
    option: (provided, state) => ({
      ...provided,
      background:
        state.isFocused && !state.isDisabled ? theme.gray : theme.layer1,
      ':active': {
        background: state.isDisabled ? theme.layer1 : theme.gray,
      },
      ':hover': {
        cursor: 'pointer',
      },
      borderRadius: '8px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      opacity: state.isDisabled ? 0.3 : 1,
      ...override?.option,
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...override?.valueContainer,
    }),
    singleValue: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? theme.secondary : 'white',
    }),
    multiValue: (provided, { data, index }) => ({
      ...provided,
      background: theme.primary,
      borderRadius: '100px',
      padding: '2px 8px',
      ...(typeof override?.multiValue === 'function'
        ? override.multiValue(data, index)
        : override?.multiValue),
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      backgroundColor: 'transparent !important',
      color: 'white !important',
    }),
    dropdownIndicator: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? theme.secondary : 'white',
      ...override?.dropdownIndicator,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: theme.secondary,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      ...override?.indicatorsContainer,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      ':hover': {
        cursor: 'pointer',
      },
      ...override?.clearIndicator,
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: 'white',
      ...override?.groupHeading,
    }),
  };
}
